.image-slide-container span {
  width: 100% !important;
}

.glide__bullets {
  @apply bottom-4 inline-flex left-1/2 absolute transform -translate-x-1/2 list-none z-[2];
}

.glide__bullet {
  @apply  w-3 md:w-1.5 h-3 md:h-1.5 mx-2 md:mx-0.5 bg-white rounded-full cursor-pointer bg-opacity-60;
}

.glide__bullet:focus {
  @apply outline-none;
}

.glide__bullet--active {
  @apply bg-opacity-100;
}

/* .glide--swipeable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.glide--dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
} */
