@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-feature-settings: 'salt' on;

    @apply antialiased text-gray-800;
  }

  @supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
  }

  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 400;
    font-display: swap;
    src: url("../public/fonts/inter/Inter-Regular.woff2") format("woff2"),
        url("../public/fonts/inter/Inter-Regular.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 400;
    font-display: swap;
    src: url("../public/fonts/inter/Inter-Italic.woff2") format("woff2"),
        url("../public/fonts/inter/Inter-Italic.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 500;
    font-display: swap;
    src: url("../public/fonts/inter/Inter-Medium.woff2") format("woff2"),
        url("../public/fonts/inter/Inter-Medium.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 600;
    font-display: swap;
    src: url("../public/fonts/inter/Inter-SemiBold.woff2") format("woff2"),
        url("../public/fonts/inter/Inter-SemiBold.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 700;
    font-display: swap;
    src: url("../public/fonts/inter/Inter-Bold.woff2") format("woff2"),
        url("../public/fonts/inter/Inter-Bold.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter var';
    font-weight: 100 900;
    font-display: swap;
    font-style: normal;
    font-named-instance: 'Regular';
    src: url("../public/fonts/inter/Inter-roman.var.woff2") format("woff2");
  }
  @font-face {
    font-family: 'Inter var';
    font-weight: 100 900;
    font-display: swap;
    font-style: italic;
    font-named-instance: 'Italic';
    src: url("../public/fonts/inter/Inter-italic.var.woff2") format("woff2");
  }
}

@layer utilities {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    @apply m-0 appearance-none;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  .focus-element:focus {
    @apply outline-none ring-offset-2 ring-2 ring-gray-200;
  }
}